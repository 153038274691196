<template>
  <h3>Stap 2 - Controle persoonlijke gegevens</h3>
  <p class="">
    Controleer hieronder uw persoonlijke gegevens. Indien deze foutief zijn
    kunt u deze aanpassen.
  </p>
  <br />

  <h5>Uw persoonlijke gegevens</h5>

  <form id="form" novalidate>
    <div class="mb-3 row">
      <label for="initials" class="col-sm-3 col-form-label">Uw naam:</label>
      <div class="col-sm-2">
        <input type="text" class="form-control" id="initials" v-model="localdossier.contact.initials" required :class="{'is-valid': localdossier.contact.initials}" :disabled="shouldDisable" @change="setInitials(localdossier.contact)" />
      </div>

      <div class="col-sm-2">
        <input type="text" class="form-control" id="prefix" v-model="localdossier.contact.prefix" :class="{'is-valid': localdossier.contact.prefix}" :disabled="shouldDisable" />
      </div>

      <div class="col-sm-5">
        <input type="text" class="form-control" id="last_name" v-model="localdossier.contact.last_name" required :class="{'is-valid': localdossier.contact.last_name}" :disabled="shouldDisable" />
      </div>
    </div>

    <template v-if="localdossier.contact.address">
      <div class="mb-3 row">
        <label for="street" class="col-sm-3 col-form-label">Adres:</label>
        <div class="col-sm-6">
          <input type="text" class="form-control" id="street" v-model="localdossier.contact.address.street" required :class="{'is-valid': localdossier.contact.address.street}" :disabled="shouldDisable" />
        </div>

        <div class="col-sm-3">
          <input type="text" class="form-control" id="house_number" v-model="localdossier.contact.address.house_number" :class="{'is-valid': localdossier.contact.address.house_number}" required :disabled="shouldDisable" />
        </div>
      </div>

      <div class="mb-3 row">
        <label for="postal_code" class="col-sm-3 col-form-label">&nbsp;</label>
        <div class="col-sm-3">
          <input type="text" class="form-control" id="postal_code" v-model="localdossier.contact.address.postal_code" :class="{'is-valid': localdossier.contact.address.postal_code}" required :disabled="shouldDisable" />
        </div>

        <div class="col-sm-3">
          <input type="text" class="form-control" id="city" v-model="localdossier.contact.address.city" required :class="{'is-valid': localdossier.contact.address.city}" :disabled="shouldDisable"/>
        </div>

        <div class="col-sm-3">
          <select v-model="localdossier.contact.address.country_id" class="form-select" id="country" required :class="{'is-valid': localdossier.contact.address.country_id}" :disabled="shouldDisable">
            <template v-for="country in countries" v-bind:key="country.id">
              <option :value="country.id">{{country.name}}</option>
            </template>
          </select>
        </div>
      </div>
    </template>
    <div class="mb-3 row">
      <label class="col-sm-3 col-form-label">Geslacht:</label>
      <div class="col-sm-6">
        <select class="form-select" v-model="localdossier.contact.gender" :class="{'is-valid': localdossier.contact.gender}" :disabled="shouldDisable">
          <option v-for="gender in genders" v-bind:key="gender.id" :value="gender.id">{{gender.name}}</option>
        </select>
      </div>
    </div>

    <div class="mb-3 row">
      <label for="bsnContact" class="col-sm-3 col-form-label">BSN nummer:</label>
      <div class="col-sm-6">
        <input @input="isValidBSN" @change="isValidBSN" maxlength="9" type="text" class="form-control" id="bsnContact" v-model="localdossier.contact.citizen_service_number" required :disabled="shouldDisable" />
        <div class="invalid-feedback">
          {{(localdossier.contact.citizen_service_number && localdossier.contact.citizen_service_number.length != 9 ? 'Een BSN nummer bestaat altijd uit 9 cijfers. Als uw BSN met een nul begint, voer deze nul dan ook in.' : 'Geef een geldig BSN nummer op')}}
        </div>
      </div>

    </div>

    <div class="mb-3 row">
      <label for="birthDateContact" class="col-sm-3 col-form-label">
        Geboortedatum:&nbsp;<i class="fa fa-info-circle info-icon"></i>
        <span class="w-25"> Uw geboortedatum is niet aan te passen omdat de uitkering gebaseerd is op uw leeftijd. </span>
      </label>
      <div class="col-sm-6">
        <input type="text" class="form-control is-valid" id="birthDateContact" :value="formatDate(localdossier.contact.birth_date, 'shortDate')" disabled />
      </div>
    </div>

    <div class="mb-3 row">
      <label for="partner" class="col-sm-3 col-form-label">Partner:&nbsp;
        <i class="fa fa-info-circle info-icon" v-if="localdossier.has_partner && localdossier.partner.insure_partner_pension == 1"></i>
        <span class="w-25" v-if="localdossier.has_partner && localdossier.partner.insure_partner_pension == 1">
          Uw partner is niet aan te passen omdat de uitkering gebaseerd is op deze keuze.
        </span>
      </label>

      <div class="col-sm-9 mt-2">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="partner-Y" :value="true" :disabled="shouldDisable || (localdossier.has_partner && localdossier.partner.insure_partner_pension == 1)"
            v-model="localdossier.has_partner" name="has-partner" @change="setPartner(true)" />
          <label class="form-check-label" for="partner-Y">Ja</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="partner-N" :disabled="shouldDisable || (localdossier.has_partner && localdossier.partner.insure_partner_pension == 1)" :value="false" 
          v-model="localdossier.has_partner" name="has-partner" @change="setPartner(false)" />
          <label class="form-check-label" for="partner-N">Nee</label>
        </div>
      </div>
    </div>

    <div class="mb-3 row" v-if="localdossier.has_partner">
      <label for="meeverzekeren" class="col-sm-3 col-form-label">Meeverzekeren:&nbsp;
        <i class="fa fa-info-circle info-icon"></i>
        <span class="w-25"> Het meeverzekeren van uw partner is niet aan te passen omdat de uitkering gebaseerd is op deze keuze. </span>
      </label>

      <div class="col-sm-9 mt-2">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="meeverzekeren-Y" :value="1" :disabled="true" v-model="localdossier.partner.insure_partner_pension" name="ins-partner" />
          <label class="form-check-label" for="meeverzekeren-Y">Ja</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="meeverzekeren-N" :value="0" :disabled="true" v-model="localdossier.partner.insure_partner_pension" name="ins-partner" />
          <label class="form-check-label" for="meeverzekeren-N">Nee</label>
        </div>
      </div>
    </div>

    <div class="mb-3 row" v-if="localdossier.has_partner">
      <label for="marital_status" class="col-sm-3 col-form-label">Burgerlijke staat:</label>
      <div class="col-sm-6">
        <select class="form-select" id="marital_status" v-model="localdossier.contact.marital_status" :class="{'is-valid': localdossier.contact.marital_status}" :disabled="shouldDisable">
          <option :value="null">Selecteer...</option>
          <template v-for="marital_status in cStatuses" v-bind:key="marital_status.id">
            <option :value="marital_status.id">{{marital_status.name}}</option>
          </template>
        </select>
        <div class="invalid-feedback">Geef een geldige burgerlijke staat op.</div>
      </div>
    </div>
    <div class="mb-3 row" v-else>
      <label for="marital_status_disabled" class="col-sm-3 col-form-label">Burgerlijke staat:</label>
      <div class="col-sm-6">
        <div class="form-select" id="marital_status_disabled" style="background-color: var(--bs-secondary-bg)"> Alleenstaand </div>
      </div>
    </div>

    <br />
    <template v-if="localdossier.has_partner">

      <h5>Persoonlijke gegevens van uw partner</h5>

      <div class="form-check">
        <input class="form-check-input" type="checkbox" v-model="partnerNotSameAddress" id="partnerSameAddress" :disabled="shouldDisable" :true-value="1" :false-value="0"/>
        <label class="form-check-label" for="partnerSameAddress"> De adresgegevens van mijn partner zijn afwijkend aan bovenstaande gegevens </label>
      </div>

      <br />
      <div class="mb-3 row">
        <label for="initialsPartner" class="col-sm-3 col-form-label">Uw partner:</label>
        <div class="col-sm-2">
          <input type="text" class="form-control" id="initialsPartner" v-model="localdossier.partner.initials" required :class="{'is-valid': localdossier.partner.initials}" :disabled="shouldDisable" @change="setInitials(localdossier.partner)" />
        </div>

        <div class="col-sm-2">
          <input type="text" class="form-control" id="prefixPartner" v-model="localdossier.partner.prefix" :class="{'is-valid': localdossier.partner.prefix}" :disabled="shouldDisable" />
        </div>

        <div class="col-sm-5">
          <input type="text" class="form-control" id="lastNamePartner" v-model="localdossier.partner.last_name" required :class="{'is-valid': localdossier.partner.last_name}" :disabled="shouldDisable" />
        </div>
      </div>

      <template v-if="partnerNotSameAddress">
        <div class="mb-3 row">
          <label for="streetPartner" class="col-sm-3 col-form-label">Adres:</label>
          <div class="col-sm-6">
            <input placeholder="Straat" type="text" class="form-control" id="streetPartner"
              v-model="localdossier.partner.address.street" required :class="{'is-valid': localdossier.partner.address.street}" :disabled="shouldDisable" />
          </div>

          <div class="col-sm-3">
            <input placeholder="Huisnummer" type="text" class="form-control" id="houseNumber"
              v-model="localdossier.partner.address.house_number" required :class="{'is-valid': localdossier.partner.address.house_number}" :disabled="shouldDisable" />
          </div>
        </div>

        <div class="mb-3 row">
          <label for="postalCodePartner" class="col-sm-3 col-form-label">&nbsp;</label>
          <div class="col-sm-3">
            <input type="text" class="form-control" id="postalCodePartner" placeholder="Postcode"
              v-model="localdossier.partner.address.postal_code" required :class="{'is-valid': localdossier.partner.address.postal_code}" :disabled="shouldDisable" />
          </div>

          <div class="col-sm-3">
            <input type="text" class="form-control" id="cityPartner" placeholder="Stad"
              v-model="localdossier.partner.address.city" required :class="{'is-valid': localdossier.partner.address.city}" :disabled="shouldDisable" />
          </div>

          <div class="col-sm-3">
            <select v-model="localdossier.partner.address.d" class="form-select" id="country" required :class="{'is-valid': localdossier.partner.address.d}" :disabled="shouldDisable">
              <template v-for="country in countries" v-bind:key="country.id">
                <option :value="country.id">{{country.name}}</option>
              </template>
            </select>
          </div>
        </div>
      </template>

      <div class="mb-3 row">
        <label for="genderPartner" class="col-sm-3 col-form-label">Geslacht:</label>
        <div class="col-sm-6">
          <select class="form-select" id="genderPartner" v-model="localdossier.partner.gender" :class="{'is-valid': localdossier.partner.gender}" :disabled="shouldDisable">
            <template v-for="gender in genders" v-bind:key="gender.id">
              <option :value="gender.id">{{gender.name}}</option>
            </template>
          </select>
          <div class="invalid-feedback">Kies een geslacht.</div>
        </div>
      </div>

      <div class="mb-3 row" v-if="localdossier.partner.insure_partner_pension == 1">
        <label for="bsnPartner" class="col-sm-3 col-form-label">BSN nummer:</label>
        <div class="col-sm-6">
          <input maxlength="9" @input="isValidBSN" @change="isValidBSN" type="text" class="form-control" id="bsnPartner" v-model="localdossier.partner.citizen_service_number" required :disabled="shouldDisable" />
          <div class="invalid-feedback">
            {{(localdossier.partner.citizen_service_number && localdossier.partner.citizen_service_number.length != 9 ? 'Een BSN nummer bestaat altijd uit 9 cijfers. Als uw BSN met een nul begint, voer deze nul dan ook in.' : 'Geef een geldig BSN nummer op')}}
          </div>
        </div>
      </div>

      <div class="mb-3 row">
        <label for="birthDatePartner" class="col-sm-3 col-form-label">
          Geboortedatum:&nbsp;<i class="fa fa-info-circle info-icon" v-if="localdossier.partner.insure_partner_pension == 1"></i>
          <span class="w-25" v-if="localdossier.partner.insure_partner_pension == 1"> De geboortedatum van uw partner is niet aan te passen omdat de uitkering gebaseerd is op deze leeftijd. </span>
     </label>
        <div class="col-sm-6">
          <input v-if="localdossier.partner.insure_partner_pension == 1" type="text" class="form-control" id="birthDatePartner" :value="formatDate(localdossier.partner.birth_date, 'shortDate')" :disabled="true" />
          <input v-else type="date" class="form-control" id="birthDatePartner" v-model="localdossier.partner.birth_date" :disabled="shouldDisable" />
        </div>
      </div>
    </template>
    <br />

    <h5>Begunstiging en uitbetalingen</h5>
    <p class="">
      De standaard begunstiging geldt, tenzij een afwijkende begunstiging is
      opgegeven. Een afwijkende begunstiging is uitsluitend toegestaan wanneer
      een oud regime lijfrente wordt aangekocht, of wanneer er
      sprake is van echtscheiding.
    </p>
    <div class="mb-3 row">

      <label class="col-sm-3 col-form-label" for="iban">IBAN:</label>
      <div class="col-sm-6">
        <input @change="isValidIBAN" type="text" class="form-control" v-model="localdossier.payroll.iban" id="iban" placeholder="" required :disabled="shouldDisable" style="text-transform: uppercase" />
        <div class="invalid-feedback">Geef een geldig IBAN nummer op.</div>
      </div>
    </div>
    <div class="mb-3 row" v-if="showBIC">

      <label class="col-sm-3 col-form-label" for="bic">BIC:</label>
      <div class="col-sm-6">
        <input @change="isValidBIC" type="text" class="form-control" v-model="localdossier.payroll.bic" id="bic" placeholder="0123456789" required :disabled="shouldDisable" />
        <div class="invalid-feedback">Geef een geldig BIC nummer op.</div>
      </div>
    </div>

    <div class="mb-3 row">
      <label for="LHK" class="col-sm-3 col-form-label">LH-korting:&nbsp;
        <i class="fa fa-info-circle info-icon"></i>
        <span class="w-50">
          Iedere werknemer of uitkeringsgerechtigde heeft recht op een fiscale
          korting, de zogenoemde loonheffingskorting. Uw werkgever of
          uitkeringsinstantie berekent de korting die voor u geldt. U krijgt deze
          korting maar bij één werkgever of uitkeringsinstantie tegelijkertijd. Op
          dit aanvraagformulier kunt u aangeven of u de loonheffingskorting door de
          verzekeraar wil laten toepassen. Omdat de loonheffingskorting al bij de
          AOW wordt toegepast raden wij aan de loonheffingskorting niet op deze
          pensioenuitkering toe te laten passen.
        </span>
      </label>

      <div class="col-sm-9 mt-2">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="LHK-Y" :value="1" :disabled="shouldDisable" v-model="localdossier.payroll.income_tax_discount" name="lhk-input" />
          <label class="form-check-label" for="LHK-Y">Ja</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" id="LHK-N" v-model="localdossier.payroll.income_tax_discount" :disabled="shouldDisable" :value="0" name="lhk-input" />
          <label class="form-check-label" for="LHK-N">Nee</label>
        </div>
      </div>
    </div>
    <br>

  </form>
  
  <button class="btn btn-secondary" @click="this.$router.push('offer')" :disabled="shouldDisable">&larr;&ensp; Vorige stap</button>
  <button class="btn btn-success float-end" @click="update" :disabled="shouldDisable">Ga verder naar stap 3 &ensp;<i class="fa fa-arrow-right"></i></button>
</template>

<script>
  import DossierService from "@/services/contact/DossierService";
  import ContactService from "@/services/contact/ContactService";
  import CountryService from "@/services/contact/CountryService";
  import IBAN from "iban";
  import BIC from "bic-validator";

  export default {
    name: "ContactAndPaymentInformation",
    props: {
      dossier: {},
    },
    computed: {
      showBIC() {
        if (!this.localdossier.payroll.iban || (this.localdossier.payroll.iban == null || this.localdossier.payroll
            .iban == '' || this.localdossier.payroll.iban.length < 2 || this.localdossier.payroll.iban.toLowerCase()
            .startsWith('nl'))) {
          return false
        } else {
          return true
        }
      },
      shouldDisable(){
          return this.dossier.max_step_id >= 23;
      },
      shouldDisablePartner(){
          return this.dossier.max_step_id >= 23;
      }
    },
    data() {
      return {
        localdossier: {
          payroll: {
            name: '',
            iban: null,
            bic: '',
            income_tax_discount: '',
          },
          contact: {
            gender: null,
            partner: {
              address: {
                street: null,
                house_number: null,
                city: null,
                postal_code: null,
                country_id: null,
              }
            }
          }
        },
        partnerNotSameAddress: false,
        genders: [{
            "id": "male",
            "name": "Man"
          },
          {
            "id": "female",
            "name": "Vrouw"
          }
        ],
        cStatuses: [{
            "id": "Gehuwd",
            "name": "Gehuwd"
          },
          {
            "id": "Geregistreerd partnerschap",
            "name": "Geregistreerd partnerschap"
          },
          {
            "id": "Ongehuwd",
            "name": "Ongehuwd"
          },
          {
            "id": "Samenwonend",
            "name": "Samenwonend"
          }
        ],
        countries: [],
        updateError: false,
        validate: {
          bsn: false,
          bsn_partner: false,
          iban: false,
          bic: false
        }

      }
    },
    methods: {
      update() {
        let form = document.getElementById("form");
        // Add 'is-invalid' class to all fields that the browser deems invalid
        let invalidGroup = form.querySelectorAll(":invalid");
        for (let j = 0; j < invalidGroup.length; j++) {
          invalidGroup[j].classList.add('is-invalid');
          //Add event listener to remove the class again after the user changes it
          invalidGroup[j].addEventListener("input", e => e.target.classList.remove("is-invalid"), {
            once: true
          });
        }

        const martialStatusEl = document.getElementById('marital_status');
        if(martialStatusEl && martialStatusEl.value == 'Selecteer...'){
          martialStatusEl.classList.add('is-invalid');
          martialStatusEl.addEventListener("input", e => e.target.classList.remove("is-invalid"), {
            once: true
          });
        }
        else if(!martialStatusEl){
          this.localdossier.contact.marital_status = 'Ongehuwd';
        }

        // Check if there are fields with the 'is-invalid' class, then don't continue submitting
        let invalid = form.querySelectorAll(".is-invalid");
        if (invalid.length) return false;

        sessionStorage.setItem('partnerSameAddress', !this.partnerNotSameAddress);
        sessionStorage.setItem('lhk', this.localdossier.payroll.income_tax_discount);
        sessionStorage.setItem('hasPartner', this.localdossier.has_partner);

        // Make Payment details calll
        const paymentPromise = DossierService
          .updatePayroll(this.dossier.id, this.localdossier.payroll)
          .then((response) => {
            this.$parent.dossier.payroll = response.data.payroll
            this.$parent.setStep(response.data.dossier_step_id);
          })
          .catch((error) => {
            this.updateError = true
            this.$toast.error("Error while storing data")
            console.log(error)
          });

        // Make Contact details call
        if (this.localdossier.partner) {
          this.localdossier.partner.same_address = !this.partnerNotSameAddress
        }
        const contactPromise = ContactService
          .updateContact(this.localdossier)
          .then((response) => {
            const c = this.localdossier.contact;
            const p = this.localdossier.partner;
            this.$parent.dossier.contact = c;
            this.$parent.dossier.contact.full_name = c.initials + (c.prefix ? ` ${c.prefix} ` : ' ') + c.last_name;
            this.$parent.dossier.contact.addressed_name = (c.gender == 'female' ? 'Mevrouw ' : 'De heer ') + this.$parent.dossier.contact.full_name;
            this.$parent.dossier.partner = p;
            this.$parent.dossier.has_partner = this.localdossier.has_partner;
            if(p){
              this.$parent.dossier.partner.full_name = p.initials + (p.prefix ? ` ${p.prefix} ` : ' ') + p.last_name;
              this.$parent.dossier.partner.addressed_name = (p.gender == 'female' ? 'Mevrouw ' : 'De heer ') + this.$parent.dossier.partner.full_name;
            }
          })
          .catch((error) => {
            this.updateError = true
            this.$toast.error("Error while storing data")
            console.log(error)
          });

        Promise.all([paymentPromise, contactPromise]).then(data => {
          if (!this.updateError) {
            this.$parent.$forceUpdate();
            // this.$toast.success("Contact & Payment information updated.")
            this.$router.push("polis");
          }
          this.updateError = false
        })
      },
      getCountries() {
        CountryService
          .getCountries()
          .then((response) => {
            this.countries = response.data
          })
          .catch((error) => {
            this.$toast.error("Error while retreiving countries");
          });
      },
      setPartner(newValue){
        if(newValue === false){
          this.localdossier.partner = null;
          this.partnerNotSameAddress = false;
          this.localdossier.contact.marital_status = 'Ongehuwd';
          return;
        }
        this.localdossier.contact.marital_status = null;
        if(this.localdossier.partner === null){
          this.localdossier.partner = {
            insure_partner_pension: 0,
            initials: "",
            prefix: null,
            last_name: "",
            gender: null,
            citizen_service_number: "",
            birth_date: null,
            address: {
              street: null,
              house_number: null,
              city: null,
              postal_code: null,
              country: null,
              country_id: null,
            }
          };
        }
        if(this.localdossier.partner.insure_partner_pension !== 0 && this.localdossier.partner.insure_partner_pension !== 1){
          this.localdossier.partner.insure_partner_pension = 0;
        }
      },
      setInitials(item){
        item.initials = item.initials.toUpperCase().replaceAll('.', '').replace(/(.)/g,"$1.");
      },
      getSumBSN(bsn) {
        let a = parseInt(bsn[0]) * 9;
        let b = parseInt(bsn[1]) * 8;
        let c = parseInt(bsn[2]) * 7;
        let d = parseInt(bsn[3]) * 6;
        let e = parseInt(bsn[4]) * 5;
        let f = parseInt(bsn[5]) * 4;
        let g = parseInt(bsn[6]) * 3;
        let h = parseInt(bsn[7]) * 2;
        let i = parseInt(bsn[8]) * -1;

        let sum = a + b + c + d + e + f + g + h + i;
        return sum;
      },
      isValidBSN(userBSN) {
        let bsn = userBSN.target.value;
        if(userBSN.type == 'input' && bsn.length != 9){
          return;
        }
        if(bsn.length != 9){
          this.showValidity(userBSN.target, false);
          return false;
        }

        let valid = true;
        if (isNaN(userBSN.target.value) || Number(userBSN.target.value) == 0 || this.getSumBSN(bsn) % 11) {
          valid = false;
        }

        if (userBSN.target.id == "bsnContact") {
          this.validate.bsn = !valid;
        } else {
          this.validate.bsn_partner = !valid;
        }
        this.showValidity(userBSN.target, valid);
        return valid;
      },
      isValidIBAN(userIBAN) {
        console.log(userIBAN);
        let valid = IBAN.isValid(userIBAN.target.value);

        this.validate.iban = !valid;
        this.showValidity(userIBAN.target, valid);
        if(valid){
          this.localdossier.payroll.iban = this.localdossier.payroll.iban.toUpperCase();
        }
        return valid;
      },
      isValidBIC(userBIC) {
        let valid = !userBIC.target.value || BIC.isValid(userBIC.target.value);

        this.validate.bic = !valid;
        this.showValidity(userBIC.target, valid);
        return valid;
      }
    },
    mounted() {
      this.getCountries()
      this.localdossier = JSON.parse(JSON.stringify(this.dossier))
      this.localdossier.quotes = null
      this.localdossier.files = null
      this.localdossier.pension_fund_history = null
      this.localdossier.step = null
      if(this.localdossier.contact.address.country_id === null){
        this.localdossier.contact.address.country_id = 158;
      }
      this.localdossier.has_partner = this.localdossier.partner ? true : false
      if (this.localdossier.partner) {
        if (!this.localdossier.partner.address) {
          this.localdossier.partner.address = {
            street: null,
            house_number: null,
            city: null,
            postal_code: null,
            country: null,
            country_id: null,
          }
        } else {
          this.partnerNotSameAddress = true
        }
      } else {
        this.localdossier.partner = {
          insure_partner_pension: 0,
          address: {
            street: null,
            house_number: null,
            city: null,
            postal_code: null,
            country: null,
            country_id: null,
          }
        }
      }
      if(!this.localdossier.contact.marital_status){
        this.localdossier.contact.marital_status = this.localdossier.partner === null ? "Ongehuwd" : null;
      }
      window.setTimeout(() => {
        const bsn1 = document.getElementById("bsnContact");
        const bsn2 = document.getElementById("bsnPartner");
        const iban = document.getElementById("iban");
        if(bsn1 && bsn1.value) this.isValidBSN({target: bsn1});
        if(bsn2 && bsn2.value) this.isValidBSN({target: bsn2});
        if(iban && iban.value) this.isValidIBAN({target: iban});
        if(sessionStorage.getItem('partnerSameAddress')) this.partnerNotSameAddress = !sessionStorage.getItem('partnerSameAddress');
        if(sessionStorage.getItem('lhk')) this.localdossier.payroll.income_tax_discount = sessionStorage.getItem('lhk');
        if(sessionStorage.getItem('hasPartner')) this.localdossier.has_partner = sessionStorage.getItem('hasPartner') == 'true' ? true : false;
      }, 100);
    },
  };
</script>