<template>
<div class="dossier-details-header">Uw uitgangspunten</div>
<button class="dossier-details-header-mobile btn btn-tertiary bold w-100" :class="{'expanded': showDetailsMobile}" @click="showDetailsMobile = !showDetailsMobile">
  Uw uitgangspunten &plus;
</button>
<section id="dossier-details" :class="{'show': showDetailsMobile}">
  <template v-if="dossier.contact">
      <div class="font-dsmbold fw-bold"><i class="fa-regular fa-user"></i>&ensp;Verzekerde</div>
      <div>
        {{dossier.contact.addressed_name}}
      </div>
      <div>{{formatDate(dossier.contact.birth_date, 'shortDate') + ' ' + calcAge(dossier.contact.birth_date)}}</div>
      <template v-if='dossier.contact.address'>
        <div>{{dossier.contact.address.street}} {{dossier.contact.address.house_number}}</div>
        <div>{{dossier.contact.address.postal_code}} {{dossier.contact.address.city}}</div>
      </template>
      <!--<table>
        <tr>
          <td>65 jaar op:</td>
          <td>{{calcSixtyFiveDate(dossier.contact.birth_date)}}</td>
        </tr>
        <tr>
          <td>AOW datum:</td>
          <td>{{calcAOWDate(dossier.contact.birth_date)}}</td>
        </tr>
      </table>-->
      <br>
      <table>
        <tr>
          <td>Tel:</td>
          <td><a class="telephone" :href="'tel:'+dossier.contact.phone_number">{{dossier.contact.phone_number}}</a></td>
        </tr>
        <tr>
          <td>E-mail:</td>
          <td>{{dossier.contact.email}}</td>
        </tr>
      </table>
    <br>
  </template>
  <template v-if="dossier.has_partner && dossier.partner">
    <div class="font-dsmbold fw-bold"><i class="fa-regular fa-user-group"></i>&nbsp;Partner</div>
      <div>
        {{dossier.partner.addressed_name}}
      </div>
      <div v-if="dossier.partner.birth_date && dossier.partner.birth_date != null">{{formatDate(dossier.partner.birth_date, 'shortDate') + ' ' + calcAge(dossier.partner.birth_date)}}</div>
      <template v-if='dossier.partner.address'>
        <div>{{dossier.partner.address.street}} {{dossier.partner.address.house_number}}</div>
        <div>{{dossier.partner.address.postal_code}} {{dossier.partner.address.city}}</div>
      </template>
      <br>
    <table>
      <tr v-if="dossier.partner_death_benefit_target">
        <td>Overgang:</td>
        <td>{{overgang[dossier.partner_death_benefit_target]}}</td>
      </tr>
      <tr v-if="dossier.partner_death_benefit">
        <td>Uitkering bij overgang:</td>
        <td>{{dossier.partner_death_benefit}}%</td>
      </tr>
      <tr>
        <td>Meeverzekerd:</td>
        <td>{{dossier.partner.insure_partner_pension ? 'Ja' : 'Nee'}}</td>
      </tr>
    </table>
    <br>
  </template>
  <template v-else>
    <div class="font-dsmbold fw-bold"><i class="far fa-user-group"></i>&ensp;Partner</div>
    <div>Geen partner</div>
    <br>
  </template>
  <div class="font-dsmbold fw-bold"><i class="fa-regular fa-coins"></i>&ensp;Uw gegevens</div>
  <table>
    <tr v-if="dossier.source">
      <td>Aanvraag vanuit:</td>
      <td class="capitalize">{{dossier.source.name}}</td>
    </tr>
    <tr v-if="dossier.pension_fund_history">
      <td>Koopsom:</td>
      <td>{{formatCurrency(totalPension, '€', 0)}}</td>
      
    </tr>
    <tr>
      <td>Ingangsdatum:</td>
      <td>{{formatDate(dossier.start_date_payroll, 'shortDate')}}</td>
    </tr>
    <tr>
      <td>Einddatum:</td>
      <td>{{dossier.end_date_payroll ? formatDate(dossier.end_date_payroll, 'shortDate') : 'Levenslang'}}</td>
    </tr>
    <tr v-if="dossier.pension_fund_history && dossier.pension_fund_history[0]">
      <td >Fiscale herkomst:</td>
      <td class="capitalize">{{dossier.pension_fund_history[0].origin}}</td>
    </tr>
    <tr>
      <td>Uitkeringstype:</td>
      <td class="capitalize">{{dossier.pension_type ? (dossier.pension_type == 'hooglaag' ? 'Hoog/Laag' : dossier.pension_type) : '-'}}</td>
    </tr>
    <tr v-show="dossier.payroll_terms != 0">
      <td>Uitkering per:</td>
      <td>{{formatMonthPeriod(dossier.payroll_terms, false)}}</td>
    </tr>
    <tr v-show="dossier.pension_type_high_years != 0">
      <td>Periode hoog/laag:</td>
      <td>{{dossier.pension_type_high_years}} jaar</td>
    </tr>
  </table>

  <ChosenProvider v-if="showchosenprovider && dossier.quotes.length > 0" :dossier="dossier"></ChosenProvider>
</section>
</template>

<script>
  import ChosenProvider from '@/components/dossier/ChosenProvider'

  export default {
    name: 'DossierDetailsSection',
    props: {
      dossier: {
        type: Object
      },
      showchosenprovider: {
        type: Boolean,
        default: false
      }
    },
    components: {
      ChosenProvider
    },
    computed: {
      totalPension: function(){
        let total = [];
        Object.entries(this.dossier.pension_fund_history).forEach(([key, val]) => {
            total.push(val.fund)
        });
        return total.reduce(function(total, num){ return parseFloat(total) + parseFloat(num) }, 0);
      }
    },
    data() {
      return {
        showDetailsMobile: false,
        overgang: {
          'll': 'Langstlevende',
          'mv': 'Medeverzekerde'
        }
      }
    },
    methods: {
      setTitle(gender){
        return gender == 'male' ? 'Dhr. ' : 'Mevr. ';
      },
      calcAge(birthDate){
        let bdate = new Date(birthDate);
        let now = new Date();
        let age = now.getFullYear() - bdate.getFullYear();
        let monthDiff = now.getMonth() - bdate.getMonth();
        if(monthDiff < 0 || (monthDiff === 0 && now.getDate() < bdate.getDate())) age--;
        return '(' + age + ' jaar)';
      },
      calcSixtyFiveDate(date){
        const year = date.substring(0, 4);
        const newyear = Number(year) + 65;
        let newdate = date.replace(year, newyear);
        if(newdate.includes("02-29")){
          newdate = newdate.replace("02-29", "03-01");
        }
        return this.formatDate(newdate, 'shortDate');
      },
      calcAOWDate(date){
        const newdate = date.replace(date.substring(0, 4), Number(date.substring(0, 4)) + 67);
        return this.formatDate(newdate, 'shortDate');
      }
    }
  }
</script>